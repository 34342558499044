<!-- @Author: Yu_Bo -->
<template>
    <div class='login_main'>
        <div class="login">
            <img class="logo" src="@/assets/images/img/logo_top.png" alt="">
            <div class="main">
                <div class="main_box">
                    <img class="top_icon" src="@/assets/images/img/Welcome.png" alt="">
                    <div class="title">欢迎登录云川平台</div>
                    <sign-in v-if="loginRegster" ref="signIn" @meun='meunBtn' @signInBtn='signInBtn'
                        @imgBtn='imgCodeBtn'></sign-in>
                    <sign-up v-else ref="signUp" @meun='meunBtn' @signUpBtn='signUpBtn' @imgBtn='imgCodeBtn'></sign-up>
                </div>
            </div>
            <!-- 发送验证码滑块验证 -->
            <el-dialog class="zc_dialog_box" :visible.sync="dialogVisible" width="440px" top="30vh">
                <div class="title" slot="title">
                    <div class="name">请完成安全验证</div>
                </div>
                <div class="verify_main">
                    <div class="ver_succ" v-if="succShow">{{ succTime }} 验证成功</div>
                    <div class="ver_err" v-if="errShow">验证失败</div>
                    <slide-verify :l="40" :r="6" :w="400" :h="200" :imgs="picArray" slider-text="向右滑动完成验证" ref="slideblock"
                        @success="onSuccess" @fail='onFail' @fulfilled='onFulfilled'></slide-verify>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { mixinIndex } from "@/mixin/index"
import { mixinVerify } from "@/mixin/verify"
import { arr_list } from '@/utils/role'
import SignIn from './sign_in.vue'
import SignUp from './sign_up.vue'
export default {
    mixins: [mixinIndex, mixinVerify],
    components: { SignIn, SignUp },
    props: {},
    data() {
        return {
            loginRegster: true,
            //
            dialogVisible: false,
            succShow: false,
            succTime: 0,
            errShow: false,
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
        //
        signInBtn(val) {
            this.loginRegster = val
        },
        signUpBtn(val) {
            this.loginRegster = val
        },
        // 验证滑块
        imgCodeBtn(val) {
            this.dialogVisible = true
            this.sign_type = val
            this.$nextTick(function () {
                this.$refs.slideblock.reset()
            })
        },
        //验证成功
        onSuccess(val) {
            var that = this
            this.succTime = (val / 1000).toFixed(2)
            this.succShow = true
            setTimeout(function () {
                that.dialogVisible = false
                if (that.sign_type == 1) {
                    that.$refs.signIn.getCodeBtn()
                }
                if (that.sign_type == 2) {
                    that.$refs.signUp.getCodeBtn()
                }
            }, 1000)
        },
        // 验证失败
        onFail() {
            this.errShow = true
        },
        // 刷新成功
        onFulfilled() {
            this.succTime = 0
            this.succShow = false
            this.errShow = false
        },
        // 登录成功跳转
        meunBtn() {
            var that = this
            that.$publicApi.routeList().then(res => {
                if (res.code == 1000) {
                    that.$store.commit('asideNav/SET_ROLELIST', res.result)
                    // 跳转
                    var ids = res.result[0]
                    var obj = arr_list.find(item => item.id == ids)
                    if (obj) {
                        that.$router.push(obj.path)
                    } else {
                        that.$errMsg('该账号没有权限')
                    }
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
    },
}
</script>

<style lang='scss' scoped>
.login_main {
    width: 100%;
    height: 100%;

    .login {
        overflow: hidden;
        width: 100%;
        height: 100vh;
        min-width: 960px;
        min-height: 650px;
        padding: 0 230px;
        background: url(~@/assets/images/background/sigin_background.jpg) no-repeat 100%;
        background-size: cover;
        background-attachment: fixed;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .logo {
            position: absolute;
            top: 40px;
            left: 40px;
            display: block;
            width: 151px;
            height: 56px;
        }

        .main {
            position: relative;
            z-index: 1;
            width: 500px;
            height: 620px;
            background: #fff;
            border-radius: 10px;

            .box_bg {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                display: block;
                width: 100%;
                height: 100%;
                border-radius: 10px;
            }

            .main_box {
                position: relative;
                z-index: 2;
                width: 100%;
                height: 100%;
                padding: 44px 45px 0;

                .top_icon {
                    display: block;
                    width: 97px;
                    height: 39px;
                    margin: 0 auto 18px;
                }

                .title {
                    padding-bottom: 14px;
                    text-align: center;
                    font-size: 22px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                }

            }
        }
    }
}

/*  */
.verify_main {
    position: relative;
    width: 100%;

    .ver_succ {
        position: absolute;
        top: 170px;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        color: #ffff;
        font-size: 12px;
        background: rgba(103, 194, 58, 0.5);
    }

    .ver_err {
        position: absolute;
        top: 170px;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        color: #ffff;
        font-size: 12px;
        background: rgba(245, 108, 108, 0.5);
    }
}
</style>
