<!-- @Author: Yu_Bo -->
<template>
  <div class="sign_in">
    <el-form :model="regsterForm" :rules="regsterRules" ref="regsterForm" label-width="0px" class="demo-ruleForm">
      <el-form-item prop="phone">
        <div class="form_phone">
          <img class="icon" v-if="regsterForm.phone" src="@/assets/images/icon/sigin_icon6.png" alt="">
          <img class="icon" v-else src="@/assets/images/icon/sigin_icon1.png" alt="">
          <span class="xian" :class="regsterForm.phone?'xian_active':''"></span>
          <el-input v-model="regsterForm.phone" @input="changeValue" maxlength="11" placeholder="请输入您的手机号"></el-input>
        </div>
      </el-form-item>
      <el-form-item prop="code">
        <div class="form_code">
          <img class="icon" v-if="regsterForm.code" src="@/assets/images/icon/sigin_icon7.png" alt="">
          <img class="icon" v-else src="@/assets/images/icon/sigin_icon2.png" alt="">
          <span class="xian" :class="regsterForm.code?'xian_active':''"></span>
          <div class="code_text">
            <el-input v-model="regsterForm.code" placeholder="请输入验证码"></el-input>
            <el-button :disabled='disabled' type="text" @click='getImgCodeBtn'>{{codeTxt}}</el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item prop="invCode">
        <div class="form_inv">
          <img class="icon" v-if="regsterForm.invCode" src="@/assets/images/icon/sigin_icon8.png" alt="">
          <img class="icon" v-else src="@/assets/images/icon/sigin_icon5.png" alt="">
          <span class="xian" :class="regsterForm.invCode?'xian_active':''"></span>
          <div class="inv_text">
            <el-input v-model="regsterForm.invCode" placeholder="请输入邀请码"></el-input>
            <el-tooltip placement="bottom">
              <div slot="content" class="inv_tip">
                <div class="tip_title">尊敬的用户，您好：</div>
                <div class="tip_txt">现平台账号体系升级，需要获取邀请码进行登录；为不影响您的正常使用，请扫描下方二维码进行邀请码获取，感谢您的配合～</div>
                <div class="tip_img">
                  <img src="@/assets/images/img/service_img.png" alt="">
                </div>
              </div>
              <el-button type="text">如何获取邀请码?</el-button>
            </el-tooltip>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div class="change_text">
      <div class="left">
        <img v-if="agree_on" @click="changeLeftBtn(2)" src="../../assets/images/icon/sigin_icon3.png" alt="">
        <img v-else @click="changeLeftBtn(1)" src="../../assets/images/icon/sigin_icon4.png" alt="">
        <span>我已阅读并同意</span>
        <span class="span_color" @click="blankToBtn('service')">《服务协议》</span>
        <span class="span_color" @click="blankToBtn('privacy')">《隐私政策》</span>
      </div>
      <div class="right">
        <img v-if="seven_time" @click="changeRightBtn(2)" src="../../assets/images/icon/sigin_icon3.png" alt="">
        <img v-else @click="changeRightBtn(1)" src="../../assets/images/icon/sigin_icon4.png" alt="">
        <span>7天内免登录</span>
      </div>
    </div>
    <div class="form_btn">
      <el-button type="primary" :loading="btnloading" class="btn_txt btnBgColor_blue" round @click="submitForm('regsterForm')">注册</el-button>
    </div>
    <div class="form_bot">
      <div class="left">
        <span>没有账号?</span>
        <span class="span_color" @click="loginRegsterBtn">马上登录</span>
      </div>
      <div class="xian"></div>
      <div class="right">
        <span>登录问题请 </span>
        <el-popover popper-class="popovre_text" placement="bottom" width="166" trigger="hover">
          <div class="popover_box">
            <div class="box_img">
              <img src="@/assets/images/img/service_img.png" alt="">
            </div>
          </div>
          <span class="span_color" slot="reference">联系管理员</span>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    mixinIndex
  } from "@/mixin/index"
  export default {
    mixins: [mixinIndex],
    components: {},
    props: {},
    data() {
      return {
        // 登录
        regsterForm: {
          phone: '',
          code: '',
          invCode: "",
        },
        disabled:false,
        codeTxt:'获取验证码',
        codeTime:60,
        timer:null,
        //
        agree_on: false,
        seven_time: true,
        //
        btnloading:false,
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      // 手机号输入框判断必须是数字
      changeValue(value) {
        this.regsterForm.phone = /^[0-9]*$/.test(parseInt(value)) ? String(parseInt(value)).replace(".", "") : ""
      },
      // 滑块验证码
      getImgCodeBtn(){
        if(this.regsterForm.phone=='' || !this.$testVerify.isvalidPhone(this.regsterForm.phone)){
          this.$errMsg('请输入正确手机号')
        }else{
          this.$emit('imgBtn',2)
        }
      },
      // 获取验证码
      getCodeBtn(){
        var that = this
        if(that.disabled){
          return
        }
        if (!this.timer) {
          that.disabled = true
          that.codeTxt = that.codeTime+'s'
          that.timer = setInterval(() => {
            if (that.codeTime > 0 && that.codeTime <= 60) {
              that.codeTime--
              that.codeTxt = that.codeTime+'s'
            } else {
              that.disabled = false
              that.codeTime = 60
              that.codeTxt = '重新获取'
              clearInterval(that.timer)
              that.timer = null;
            }
          }, 1000)
        }
      },
      // 同意阅读
      changeLeftBtn(type){
        if(type==1){
          this.agree_on=true
        }else{
          this.agree_on=false
        }
      },
      // 7天免登录
      changeRightBtn(type){
        if(type==1){
          this.seven_time=true
        }else{
          this.seven_time=false
        }
      },
      // 马上登录
      loginRegsterBtn(){
        this.$emit('signUpBtn',true)
      },
      // 注册
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // 验证通过
            this.submitBtn()
          } else {
            // 验证不通过
            return false;
          }
        });
      },
      submitBtn(){
        var that = this
        if(!that.agree_on){
          that.$errMsg('请先阅读并勾选服务协议及隐私政策')
        }else{
          that.btnloading=true
          var params = {
            mobile: that.regsterForm.phone,
            sms_code: that.regsterForm.code,
            invite_code: that.regsterForm.invCode,
            is_cache:that.seven_time?1:2
          }
          that.$publicApi.postRegister(params).then(res => {
            that.btnloading = false
            if (res.code == 1000) {
              that.$succMsg(res.message)
              // 储存token
              that.$store.commit('SET_TOKEN',res.result.token)
              // 储存用户信息
              that.$store.dispatch('userInfo/getUserInfo')
              that.$store.dispatch('userInfo/getConfig')
              // 7天免登录
              if(that.seven_time){
                // 获取7天后的时间戳
                var time = parseInt(new Date().getTime() / 1000) + 7*24*60*60
                that.$store.commit('SET_TIME',time)
              }else{
                that.$store.commit('SET_TIME','')
              }
              // 获取路由跳转
              that.$emit('meun')
            } else {
              that.$errMsg(res.message)
            }
          })
        }
      },
      // 服务协议 隐私政策
      blankToBtn(val){
        const { href } = this.$router.resolve({
          path: "/textPage/"+val,
        });
        window.open(href, "_blank")
      }
    },
  }
</script>

<style lang='scss' scoped>
  .sign_in {
    .el-form-item {
      margin-bottom: 0;
    }

    .form_phone,
    .form_code,
    .form_inv {
      margin-top: 30px;
      width: 100%;
      height: 50px;
      padding: 0 20px;
      background: #F8F8F8;
      border-radius: 2px;
      display: flex;
      justify-content: space-between;

      .icon {
        display: block;
        width: 20px;
        height: 20px;
        margin: 15px 0;
      }

      .xian {
        display: block;
        height: 16px;
        width: 1px;
        margin-left: 11px;
        margin-top: 17px;
        background: #DCDCDC;
      }
      .xian_active{
        background: #2B4AFE;
      }

      .el-input {
        width: 358px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333;
        background: none;
        display: flex;
        justify-content: center;
        flex-direction: column;
        ::v-deep .el-input__inner{
          border: none;
          background: none;
        }
      }

      .code_text {
        width: 358px;
        display: flex;
        justify-content: space-between;

        .el-input {
          width: 230px;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333;
          ::v-deep .el-input__inner{
            border: none;
            background: none;
          }
        }

        .el-button {
          width: 85px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          padding: 0;
        }
        .is-disabled{
          color: #2B4AFE;
        }
      }

      .inv_text {
        width: 358px;
        display: flex;
        justify-content: space-between;

        .el-input {
          width: 200px;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333;
          ::v-deep .el-input__inner{
            border: none;
            background: none;
          }
        }

        .el-button {
          height: 50px;
          line-height: 50px;
          text-align: center;
          padding: 0;
        }
      }
    }
    ::v-deep .el-form-item__error{
      left: 67px;
    }
    .change_text {
      width: 100%;
      padding-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;

        img {
          cursor: pointer;
          display: block;
          width: 14px;
          height: 14px;
          margin-right: 10px;
        }

        span {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }

        .span_color {
          cursor: pointer;
          color: #2E4BF2;
        }
      }

      .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        img {
          cursor: pointer;
          display: block;
          width: 14px;
          height: 14px;
          margin-right: 10px;
        }

        span {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
    }

    .form_btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .btn_txt {
        width: 250px;
        margin: 50px 0 40px;
        padding: 12px 23px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        background: linear-gradient(90deg, #5F87FF 0%, #2B4AFE 100%);
        box-shadow: 0px 4px 14px 0px rgba(96, 123, 255, 0.59);
      }
    }

    .form_bot {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .xian {
        margin: 0 20px;
        width: 1px;
        height: 15px;
        background: #DCDCDC;
      }

      .left,
      .right {
        display: flex;

        span {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }

        .span_color {
          cursor: pointer;
          padding-left: 8px;
          color: #2E4BF2;
        }
      }
    }
  }

  /* 如何获取邀请码? */
  .inv_tip {
    width: 290px;
    line-height: 19px;
    padding-bottom: 10px;

    .tip_title {
      padding-bottom: 5px;
    }

    .tip_txt {
      padding-bottom: 10px;
    }

    .tip_img {
      box-sizing: border-box;
      width: 160px;
      margin: auto;
      padding: 10px 10px;
      background: #fff;

      img {
        display: block;
        width: 100%;
      }
    }
  }
</style>
