export const mixinVerify = {
  data() {
    return {
      // 滑块验证=图片
      picArray:[
        require("@/assets/images/verify_img/images_a.png"),
        require("@/assets/images/verify_img/images_b.png"),
        require("@/assets/images/verify_img/images_c.png"),
        require("@/assets/images/verify_img/images_d.png"),
        require("@/assets/images/verify_img/images_e.png"),
        require("@/assets/images/verify_img/images_f.png"),
        require("@/assets/images/verify_img/images_g.png"),
        require("@/assets/images/verify_img/images_h.png"),
        require("@/assets/images/verify_img/images_i.png"),
        require("@/assets/images/verify_img/images_j.png"),
        require("@/assets/images/verify_img/images_k.png"),
        require("@/assets/images/verify_img/images_l.png"),
        require("@/assets/images/verify_img/images_m.png"),
        require("@/assets/images/verify_img/images_n.png"),
      ],
    }
  },
  computed: {

  },
  created() {},
  mounted() {},
  methods: {},
}
